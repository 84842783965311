// import "./App.css";
import { useSelector } from "react-redux";
import Header from "./Components/header";
import TreeContainer from "./Components/treeContainer";
import json from "./json";

function App() {
  const state = useSelector((state) => state);
  return (
    <div id="container">
      <Header filter={state.filter} timestamp={json.timestamp} />
      <TreeContainer
        activeNode={state.activeNode}
        data={json}
        filter={state.filter}
        height={state.height}
        width={state.width}
      />
    </div>
  );
}

export default App;
