const jsonObject = {
  timestamp: "25/10/2022 12:00",
  name: "Color",
  children: [
    {
      name: "Black",
    },
    {
      name: "Blue",
      children: [
        {
          name: "Aquamarine",
        },
        {
          name: "Cyan",
        },
        {
          name: "Navy",
        },
        {
          name: "Turquoise",
        },
      ],
    },
    {
      name: "Green",
    },
    {
      name: "Purple",
      children: [
        {
          name: "Indigo",
        },
        {
          name: "Violet",
        },
      ],
    },
    {
      name: "Red",
      children: [
        {
          name: "Crimson",
        },
        {
          name: "Maroon",
        },
        {
          name: "Scarlet",
        },
      ],
    },
    {
      name: "White",
    },
    {
      name: "Yellow",
    },
  ],
};

export default jsonObject;
